import router from "@/router";
import store from "@/store";
import { ICabinet, ICard } from "@/struct/IHomePage/ICabinetBalance";
import UtilStorage from "@/util/Common/UtilStorage";
import { Capacitor } from "@capacitor/core";

export default class UtilError {
  public static validateError(
    statusCode: number,
    url: string,
    response_data: any,
  ): void {
    const showError = (
      error_message: string,
      replace_message = false,
      type: "error" | "warning" = "error",
    ) => {
      let message;
      if (!replace_message) {
        message = `<div>${error_message}</div> <div>Код ошибки: ${statusCode}</div> <div>Адрес запроса: ${url}</div><div>Отправьте скриншот данной ошибки Администратору!</div>`;
        (window as any).ErrorModal.Show(message, type);
        return;
      }
      message = `<div>${error_message}</div>`;
      (window as any).ErrorModal.Show(message, type);
    };

    if (statusCode === 401) {
      UtilStorage.removeItem("token");
      router.replace("/");
      return;
    }

    if (statusCode === 403) {
      const error_message =
        "Проблема прав доступа к этому функционалу. Обратитесь к Администратору";

      return showError(error_message, true);
    }

    if (statusCode === 429) {
      const error_message =
        "Вы выполняете действие слишком часто, подождите пожалуйста";

      return showError(error_message, true);
    }

    if (statusCode === 422) {
      let errorMessage = "Ошибка: ";
      response_data.detail.forEach((detail: { msg: string; loc: any[] }) => {
        errorMessage += `${detail.msg} (${detail.loc.join(".")})`;
      });

      return showError(errorMessage);
    }

    if (statusCode === 409) {
      if (response_data && response_data.detail && response_data.detail.id) {
        const error_message =
          "Неизвестная ошибка на сервере: \n Номер ошибки: " +
          response_data?.detail?.id;
        return showError(error_message);
      }

      return showError("Неожиданная ошибка на сервере");
    }

    if (statusCode === 500) {
      return showError("Неожиданная ошибка на сервере");
    }
    if (statusCode === 504) {
      return showError(
        "Неожиданная ошибка на сервере. Статус 504. Повторите действие чуть позже",
      );
    }

    if (statusCode === 502) {
      return showError(
        "Неожиданная ошибка на сервере. Статус 502. Повторите действие чуть позже",
      );
    }

    return showError("Ошибка: " + "\n Ответ сервера: " + response_data);
  }

  public static async validateErrorMessage(message: string, phone?: string) {
    const showError = (error_message: string) => {
      (window as any).ErrorModal.Show(error_message, "error");
    };

    if (!phone) phone = (await UtilStorage.getItem("phone")) as string;

    if (
      message === "DRIVER_CARD_DELETE_ERROR" ||
      message === "driver_card_delete_error"
    ) {
      return showError(
        `Ошибка удаления карты. Пожалуйста отправьте эту ошибку в службу технической поддержки.`,
      );
    }

    if (message === "user_not_activate" || message === "USER_NOT_ACTIVATE") {
      return showError(`${phone} не активирован. Обратитесь к партнеру.`);
    }

    if (
      message === "user_not_right_register" ||
      message === "USER_NOT_RIGHT_REGISTER"
    ) {
      return showError(
        `${phone} не числится в списке пользователей. Обратитесь к партнеру.`,
      );
    }
    if (message === "user_not_register" || message === "USER_NOT_REGISTER") {
      return showError(
        `${phone} не числится в списке пользователей. Обратитесь к партнеру.`,
      );
    }

    if (message === "USER_NOT_EXISTS" || message === "user_not_exists") {
      return showError(
        `Профиля с номером ${phone} нет в списке исполнителей.
        Убедитесь, что после создания профиля или смены номера телефона прошло более 40 минут.`,
      );
    }
    if (
      message === "LOGIN_BAD_CREDENTIALS" ||
      message === "login_bad_credentials"
    ) {
      return showError(
        `Вы ввели неверный код-пароль для аккаунта ${phone}. Если Вы его забыли, обратитесь к партнеру для сброса.`,
      );
    }

    if (
      message === "DRIVER_SBP_ALREADY_EXIST" ||
      message === "DRIVER_CARD_ALREADY_EXIST" ||
      message === "driver_card_already_exist" ||
      message === "driver_sbp_already_exist"
    ) {
      return showError(
        `${phone} к данному номеру уже привязана такая карта для вывода.`,
      );
    }

    if (message === "OTP_ERROR" || message === "otp_error") {
      return showError(
        `К сожалению, сейчас мы не можем отправить код подтверждения. Пожалуйста, повторите попытку позже`,
      );
    }

    if (message === "DUPLICATE_CALL" || message === "duplicate_call") {
      return showError(
        `К сожалению, сейчас мы не можем отправить код подтверждения. Пожалуйста, повторите попытку позже`,
      );
    }

    if (message === "no_partner_score" || message === "NO_PARTNER_SCORE") {
      return showError(
        `Попросите партнера подключить СБП для экономии на переводах.`,
      );
    }

    if (message === "INVALID_DATA" || message === "invalid_data") {
      return showError(
        "Неверные параметры запроса. Обратитесь к администратору",
      );
    }
    if (message === "INVALID_DEVICE" || message === "invalid_device") {
      return showError(
        "Выполнен запрос с неверного устройства. Обратитесь к администратору",
      );
    }

    if (message === "error_check_system" || message === "ERROR_CHECK_SYSTEM") {
      return showError(`Ошибка проверки номера телефона в системе СБП`);
    }

    if (
      message === "phone_missing_the_bank" ||
      message === "PHONE_MISSING_THE_BANK"
    ) {
      return showError(
        `Номер телефона в системе банка не зарегистрирован, выберите другой банк`,
      );
    }

    if (message === "short_card_name" || message === "SHORT_CARD_NAME") {
      return showError("Название карты должно содержать минимум 3 символа");
    }

    if (
      message === "sbp_bank_dont_choose" ||
      message === "SBP_BANK_DONT_CHOOSE"
    ) {
      return showError(`Выберите банк из списка чтобы добавить СБП карту`);
    }

    if (
      message === "exceeded_the_payout_limit" ||
      message === "EXCEEDED_THE_PAYOUT_LIMIT"
    ) {
      return showError(
        "Вывод денег доступен один раз в 15 минут. Подождите пожалуйста",
      );
    }

    if (message === "payout_disabled" || message === "PAYOUT_DISABLED") {
      return showError("Выплаты отключены. Уточните график выплат у партнера");
    }

    if (message === "time_out" || message === "TIME_OUT") {
      return showError(
        "Проблема с интернет соединением. Проверьте ваше подключение к интернету и повторите попытку",
      );
    }

    if (
      message === "finished_trying_enter_code" ||
      message === "FINISHED_TRYING_ENTER_CODE"
    ) {
      return showError(
        "Превышено количество попыток ввода пароля. Снять блокировку может Ваш партнер",
      );
    }

    if (
      message === "limiting_input_attempts" ||
      message === "LIMITING_INPUT_ATTEMPTS"
    ) {
      return showError(
        "Превышено количество попыток ввода. Попробуйте позже или обратитесь к вашему партнеру",
      );
    }

    if (message === "duplicate_call" || message === "DUPLICATE_CALL") {
      return showError("Проблема отправки повторного звонка. Попробуйте позже");
    }

    if (
      message === "error_with_request_login" ||
      message === "ERROR_WITH_REQUEST_LOGIN"
    ) {
      if (Capacitor.getPlatform() != "web") {
        return showError(
          "Проблемы с логином. Попробуйте попытку еще раз или перейдите веб версию - https://pro.force2.ru. Номер телефона: " +
            phone,
        );
      } else {
        return showError(
          "Проблемы с логином. Попробуйте попытку еще раз или напишите в тех поддержку. Номер телефона: " +
            phone,
        );
      }
    }

    if (message === "incorrect_password" || message === "INCORRECT_PASSWORD") {
      return showError(
        `Вы ввели неверный код-пароль для аккаунта ${phone}. Если Вы его забыли, обратитесь к партнеру для сброса.`,
      );
    }

    if (message === "access_denied" || message === "ACCESS_DENIED") {
      return showError(
        "Этот номер используется для входа в кабинет партнера. Доступ в приложение для водителя запрещен.",
      );
    }

    if (message === "repeat_cabinets" || message === "REPEAT_CABINETS") {
      return showError(
        "Не удалось проверить корректность суммы. пожалуйста, обновите страницу.",
      );
    }

    if (message === "otp_error" || message === "OTP_ERROR") {
      return showError(
        "Проблема с отправкой звонка. Вы можете авторизоваться через Telegram без получения звонка если у Вас этот номер телефона: " +
          phone,
      );
    }

    showError(
      "Неизвестная ошибка, свяжитесь с тех поддержкой, код ошибки: " + message,
    );
  }

  public static async validateWithdrawError(
    userActiveBalanceWithdraw: ICabinet | null,
    withdrawBalance: string,
    cards: ICard[],
    waSupport: string,
  ): Promise<string | boolean> {
    if (!userActiveBalanceWithdraw) {
      return "Ошибка, код: 'withdraw_cabinet_null'. Отправьте его Администратору!";
    }
    if (!cards?.length) {
      return "У вас нет карт для вывода, добавьте карту и попробуйте еще раз!";
    }

    const currentCard = cards.find((item) => item.is_main);
    if (currentCard && !currentCard.is_approved) {
      const user = store.state.userData;

      const userFullName = user?.full_name.split("null").join("");

      const waSupportNumber = waSupport;
      const textMessage = `Прошу подтвердить карту "${currentCard.name}" ${currentCard.number}. Водител ${userFullName}, номер телефона: ${user?.phone_number}`;

      const href = encodeURI(
        `https://api.whatsapp.com/send?phone=${waSupportNumber}&text=${textMessage}`,
      );
      return `Ваша карта не подтверждена. <br> Обратитесь в "парк" <br> (к партнеру). <br>  WhatsApp: ${waSupportNumber}`;
    }

    if (!userActiveBalanceWithdraw.is_payment) {
      const user = store.state.userData;

      const userFullName = user?.full_name.split("null").join("");
      const waSupportNumber = waSupport;
      const textMessage = `Водитель ${userFullName} с номером ${user?.phone_number} нажал в приложении кнопку "У меня отключены выплаты". Кабинет ${userActiveBalanceWithdraw.name}`;

      const href = encodeURI(
        `https://api.whatsapp.com/send?phone=${waSupportNumber}&text=${textMessage}`,
      );

      return `Выплаты с кабинета ${userActiveBalanceWithdraw.name} выключены.<br> Обратитесь в "парк" <br> (к партнеру). <br>  WhatsApp: ${waSupportNumber}`;
    }

    const balance = Number(withdrawBalance);
    const { amount, min_amount } = userActiveBalanceWithdraw;

    if (isNaN(balance) || balance < 0) {
      return "Сумма к выплате должна быть числом и больше нуля";
    }

    if (balance === 0) {
      return "Сумма к выплате должна быть больше нуля";
    }

    if (balance > amount) {
      return `Вы не можете вывести сумму больше, чем у вас есть. Максимальная сумма к выводу: ${amount}`;
    }

    if (balance < min_amount) {
      return `Минимальная сумма к выводу: ${min_amount}`;
    }

    if (balance > 100000) {
      return `Максимальная сумма к выводу: 100 000 руб.`;
    }

    return true;
  }

  public static async validateErrorMessageOnBackend(
    detail: string,
    message: string,
  ) {
    const showError = (error_message: string, type = "warning") => {
      (window as any).ErrorModal.Show(error_message, type);
    };

    if (!message) {
      return showError(`Не известная ошибка, код ошибки: ${detail}`, "error");
    }

    return showError(message);
  }
}
