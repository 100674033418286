<template>
  <Transition name="modal" :duration="600">
    <div
      class="relative z-50 w-full"
      aria-labelledby="modal-title"
      role="dialog"
      v-show="state || modelValue"
      aria-modal="true"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 bg-gray-400 bg-opacity-75 transition-opacity"
      />
      <div
        @click="onClickOutside"
        class="fixed top-0 right-0 left-0 bottom-0 z-10 overflow-y-hidden"
      >
        <div
          id="dialog"
          class="bounce-modal-animation flex min-h-full w-full items-center justify-center p-0 px-4 text-center"
        >
          <div class="relative h-min w-full rounded-lg bg-white p-8 shadow-xl">
            <!--              Icons-->
            <div
              class="absolute top-[-80px] right-0 left-0 z-20 flex flex-col items-center justify-center rounded-full"
            >
              <svg
                fill="white"
                color="#FFD92F"
                class="h-[150px] w-[150px]"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  fill-rule="nonzero"
                  color="#FFD92F"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <!--              Content-->
            <div
              class="mt-10 flex flex-col items-center whitespace-pre-line text-grey"
            >
              <div class="flex justify-center space-x-2">
                <div
                  v-if="platformApp"
                  class="flex items-center justify-center text-xs"
                >
                  {{ platformApp }}
                </div>
                <div
                  class="flex items-center justify-center text-xs"
                  v-if="appVersion"
                >
                  {{ appVersion }}
                </div>
              </div>
              <div
                class="flex items-center justify-center text-xs"
                v-if="userData"
              >
                {{ userData.phone_number }}
              </div>
              <div v-if="description" class="font-medium mt-3">
                {{ description }}
              </div>
              <div class="font-medium mt-3" v-if="htmlDescription">
                <p v-html="htmlDescription" @click="$emit('openWa')"></p>
              </div>
              <div class="flex space-x-2 justify-around xs:flex-col">
                <ButtonComponent
                  @clickAction="
                    $emit('decline');
                    $emit('update:modelValue', false);
                  "
                  :full-width="false"
                  height="10"
                  class="mt-5 mr-5 sm:m-[auto] sm:mt-5"
                  input-class="px-6"
                  rounded="lg"
                  :title="declineButtonText"
                  color="yellowLight"
                  v-if="declineButtonText"
                >
                  <template v-slot:title v-if="!declineButtonText">
                    <slot name="decline-title" />
                  </template>
                </ButtonComponent>
                <ButtonComponent
                  @clickAction="
                    $emit('confirm');
                    $emit('update:modelValue', false);
                  "
                  :full-width="false"
                  height="10"
                  input-class="px-6"
                  class="mt-5"
                  rounded="lg"
                  :title="confirmButtonText"
                >
                  <template v-slot:title v-if="!confirmButtonText">
                    <slot name="confirm-title" />
                  </template>
                </ButtonComponent>
              </div>
            </div>
            <!--            Footer-->
            <div
              class="absolute bottom-[-10px] left-0 right-0 z-[-1] ml-auto mr-auto h-12 w-11/12 rounded-lg bg-[#FFD92F]"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import { IUserData } from "@/struct/IStore/IStore";
import { App } from "@capacitor/app";
import { Getter } from "s-vuex-class";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Options({
  components: { ButtonComponent },
})
export default class ConfirmModal extends Vue {
  @Prop() public state!: boolean;
  @Prop() public description!: string[];
  @Prop() public htmlDescription!: string;
  @Prop() public confirmButtonText!: string;
  @Prop() public declineButtonText!: string;
  @Prop() public modelValue!: boolean;

  @Getter("validateUserPlatform") private platformApp: string | undefined;
  public appVersion: null | string = null;
  @Getter("getUserData") private userData: IUserData | undefined;

  @Watch("state")
  watchModalState(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }

  @Watch("modelValue")
  watchModalValue(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }

  private onClickOutside() {
    const dialog = document.getElementById("dialog") as HTMLDivElement;
    dialog.classList.add("bounce-modal-animation");
    setTimeout(() => {
      dialog.classList.remove("bounce-modal-animation");
    }, 100);
  }
  async created() {
    if (this.$PLATFORM != "web") {
      const appInfo = await App.getInfo();
      this.appVersion = `v${appInfo.version} ${appInfo.build}`;
    }
  }
}
</script>
